import { createRouter, createWebHistory } from 'vue-router'
import store from "@/store";

const routes = [
  {
    path: '/',
    redirect: { name: 'EvidenceDokladuKVjezduView' },
    //name: 'HomeView',
    //component: () => import('@/views/HomeView.vue'),
    //meta: {
    //  layout: 'AppLayoutMain'
    //}
  },
  
  // --- Evidence dokladu k vjezdu ---
  {
    path: '/evidence-dokladu-k-vjezdu',
    name: 'EvidenceDokladuKVjezduView',
    component: () => import('@/views/EvidenceDokladuKVjezduView.vue'),
    meta: {
      layout: 'AppLayoutMain'
    }
  },
  // --- Evidence dokladu k vjezdu do oblasti ---
  {
    path: '/evidence-dokladu-k-vjezdu-do-oblasti',
    name: 'EvidenceDokladuKVjezduDoOblastiView',
    component: () => import('@/views/EvidenceDokladuKVjezduDoOblastiView.vue'),
    meta: {
      layout: 'AppLayoutMain'
    },
    beforeEnter() {
      if (!store.state?.user?.userIsEmployee) {
        return {
          name: 'EvidenceDokladuKVjezduView'
        }
      }
    }
  },
  // --- Evidence dokladu k vjezdu do oblasti ---
  {
    path: '/krnap/prehled-objektu',
    name: 'KrnapPrehledObjektu',
    component: () => import('@/views/krnap/KrnapPrehledObjektuView.vue'),
    meta: {
      layout: 'AppLayoutMain'
    },
    beforeEnter() {
      if (!store.state?.user?.userIsEmployee) {
        return {
          name: 'EvidenceDokladuKVjezduView'
        }
      }
    }
  },
  {
    path: '/zalozit-novy-doklad',
    name: 'ZalozitNovyDokladView',
    component: () => import('@/views/ZalozitNovyDokladView.vue'),
    meta: {
      layout: 'AppLayoutMain'
    }
  },
  
  // --- Evidence objektu a ploch ---
  {
    path: '/evidence-objektu-a-ploch',
    name: 'EvidenceObjektuAPlochView',
    component: () => import('@/views/EvidenceObjektuAPlochView.vue'),
    meta: {
      layout: 'AppLayoutMain'
    }
  },
  {
    path: '/zalozit-novy-objekt',
    name: 'ZalozitNovyObjektView',
    component: () => import('@/views/ZalozitNovyObjektView.vue'),
    meta: {
      layout: 'AppLayoutMain'
    }
  },
  
  {
    path: '/podminky-pro-parkovaci-plochy-a-doklady',
    name: 'PodminkyProParkovaciPlochyADokladyView',
    component: () => import('@/views/PodminkyProParkovaciPlochyADokladyView.vue'),
    meta: {
      layout: 'AppLayoutMain'
    }
  },
  {
    path: '/zobrazit-upozorneni',
    name: 'ZobrazitUpozorneniView',
    component: () => import('@/views/ZobrazitUpozorneniView.vue'),
    meta: {
      layout: 'AppLayoutMain'
    }
  },
  {
    path: '/sprava-meho-uctu',
    name: 'SpravaMehoUctuView',
    component: () => import('@/views/SpravaMehoUctuView.vue'),
    meta: {
      layout: 'AppLayoutMain'
    }
  },
  {
    path: '/kontakt',
    name: 'KontaktView',
    component: () => import('@/views/KontaktView.vue'),
    meta: {
      layout: 'AppLayoutMain'
    }
  },
  {
    path: '/signin',
    name: 'SigninView',
    component: () => import('@/views/SigninView.vue'),
    meta: {
      layout: 'AppLayoutSignin'
    }
  },
  {
    path: '/obnova-hesla',
    name: 'ObnovaHeslaView',
    component: () => import('@/views/ObnovaHeslaView.vue'),
    meta: {
      layout: 'AppLayoutSignin'
    }
  },
  {
    path: '/vytvoreni-uctu',
    name: 'VytvoreniUctuView',
    component: () => import('@/views/VytvoreniUctuView.vue'),
    meta: {
      layout: 'AppLayoutSignin'
    }
  },
  {
    path: '/doklad-k-vjezdu/:hash',
    name: 'dokladKVjezdu',
    component: () => import('@/views/DokladKVjezdu.vue'),
    props: true,
    meta: {
      layout: 'AppLayoutMain'
    }
  },
  {
    path: '/overeni-emailu/:validationKey',
    name: 'OvereniEmailuView',
    component: () => import('@/views/OvereniEmailuView.vue'),
    props: true,
    //meta: {
    //  layout: 'AppLayoutSignin'
    //}
  },
  {
    path: '/nove-heslo/:passwordRecoveryKey',
    name: 'NoveHesloView',
    component: () => import('@/views/NoveHesloView.vue'),
    props: true,
  },
  {
    path: '/detail-dokladu/:uuid',
    name: 'DetailDokladuView',
    component: () => import('@/views/DetailDokladuView.vue'),
    props: true
  },
  {
    path: '/admin',
    name: 'AdminView',
    component: () => import('@/views/admin/AdminView.vue'),
    meta: {
      layout: 'AppLayoutMain'
    }
  },
  {
    path: '/admin/prava-objekty',
    name: 'AdminViewPravaObjekty',
    component: () => import('@/views/admin/AdminViewPravaObjekty.vue'),
    meta: {
      layout: 'AppLayoutMain'
    }
  },
  {
    path: '/admin/prava-oblasti',
    name: 'AdminViewPravaOblasti',
    component: () => import('@/views/admin/AdminViewPravaOblasti.vue'),
    meta: {
      layout: 'AppLayoutMain'
    }
  },
  {
    path: '/admin/prehled-evidence-k-objektum',
    name: 'AdminPrehledEvidenceKObjektum',
    component: () => import('@/views/admin/AdminPrehledEvidenceKObjektum.vue'),
    meta: {
      layout: 'AppLayoutMain'
    }
  },
  {
    path: '/admin/prehled-evidence-do-oblasti',
    name: 'AdminPrehledEvidenceDoOblasti',
    component: () => import('@/views/admin/AdminPrehledEvidenceDoOblasti.vue'),
    meta: {
      layout: 'AppLayoutMain'
    }
  },
  {
    path: '/detail-objektu/:id',
    name: 'DetailObjektuView',
    component: () => import('@/views/DetailObjektuView.vue'),
    props: (route) => {
      return { id: Number.parseInt(route.params.id, 10) || false }
    },
    meta: {
      layout: 'AppLayoutMain'
    }
  },
  {
    path: '/exchange-token',
    name: 'ExchangeOAuth2Token',
    component: () => import('@/views/ExchangeOAuth2Token.vue')
  },
  {
    path: '/404',
    name: 'not-found-404',
    component: () => import('@/views/ErrorCode404.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    beforeEnter() {
      return {
        name: 'not-found-404'
      }
    }
  }
] 

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const checkIfJwtExpire = () => {
  try {
    const jwt = store.state.token
    const tokens = jwt.split(".")
    const userObject = JSON.parse(window.atob(tokens[1]))
    const exp = userObject?.exp || 0
    return Date.now() >= ((exp - 86400) * 1000)
  } catch (error) {
    // Pokud nastane nějaký problém, vždy vrátí true (token expiroval)
    return true
  }
}

router.beforeEach(async (to) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['SigninView', 'ObnovaHeslaView', 'VytvoreniUctuView', 'OvereniEmailuView', 'DetailDokladuView', 'NoveHesloView', 'not-found', 'not-found-404', 'ExchangeOAuth2Token'];
  const authRequired = !publicPages.includes(to.name);
  const auth = store.state.isUserLoggedIn;

  if (authRequired && !auth) {
      //auth.returnUrl = to.fullPath;
      return '/signin';
  }

  // Pokud je user přihlášen, má token po expiraci a chce navštívit stránku která vyžaduje přihlášení,
  // tak bude přesměrován na přihlášení a user a token bude vymazán
  if (auth && checkIfJwtExpire() && authRequired) {
    store.dispatch('setToken', null)
    store.dispatch('setUser', null)
    return '/signin';
  }
});

export default router
